import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Inject } from 'vue-di-container';
import Progress from 'easy-circular-progress';
import WithRender from './ShortcodeConnectingRequest.html';
import { ShortcodeConnectingRequestModel } from './ShortcodeConnectingRequestModel';
import ShortcodeConnectingRequestService from './ShortcodeConnectingRequestService';
import { Toaster } from '../../Services/toast';
import UserService from '../../shared/userService/UserService';
import UserRoleService from '../../shared/userService/UserRoleService';
import { ShortCodeConnectivityRequestHistoryModel } from './ShortCodeConnectivityRequestHistoryModel';
import { ShortcodeRequestDetailsModel } from './ShortcodeRequestDetailsModel';
import { SkippedModel } from './SkippedModel';
import ProviderStatusService from '../../shared/ProviderStatusService/ProviderStatusService';

@WithRender
@Component({
    components: {
        Progress
    },
    diProvide: [
        ShortcodeConnectingRequestService,
        UserService,
        UserRoleService,
        ProviderStatusService,
    ],
})
export default class ShortcodeConnectingRequest extends Vue {
    @Inject(ShortcodeConnectingRequestService) public shortService!: ShortcodeConnectingRequestService;
    @Inject(UserService) public userService!: UserService;
    @Inject(UserRoleService) public userRoleService!: UserRoleService;
    @Inject(ProviderStatusService) public providerStatusService!: ProviderStatusService;

    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;
    public detailsDialog: boolean = false;
    public providerDissconected: boolean = true;
    public shortCodeId: string = '0';
    public operatorId: string = '0';
    public name: string = '0';
    public currentBulkId: string = '';
    public User: any;
    public userRole: any;
    public headers: any = [
        { text: this.$t('shortPage.company'), value: 'operatorId', align: 'center', sortable: false },
        {
            text: this.$t('shortPage.shortcodeType'), value: 'name', align: 'center', sortable: false,
        },
        {
            text: this.$t('shortPage.shortCode')
                + ' / ' +
                this.$t('shared.customerId'), value: 'name', align: 'center', sortable: false,
        },
        {
            text: this.$t('bulkshortPage.connectivityStatus'), value: 'name', align: 'center', sortable: false,
        },
        {
            text: this.$t('bulkshortPage.requestStatus'), value: 'name', align: 'center', sortable: false,
        },
        { text: this.$t('shared.actions'), sortable: false, value: 'id', align: 'center', width: '100' },
    ];
    public skippedheaders: any = [
        { text: this.$t('shared.senderName'), value: 'senderName', align: 'center', sortable: false },
        {
            text: this.$t('shared.clientName'), value: 'clientName', align: 'center', sortable: false,
        }
    ];

    public shorts: ShortCodeConnectivityRequestHistoryModel[] = [];
    public skippedModel: SkippedModel[] = [];

    public details: ShortcodeRequestDetailsModel = {
        senderLeft: 0,
        senderSkipped: 0,
        senderTotal: 0,
        shortCode: '',
        operatotrName: '',
    };

    public shortsCount: number = 0;
    public skippedCount: number = 0;

    public filterShorts: ShortcodeConnectingRequestModel[] = [];
    public filterModel: any = {
        id: '',
        operator: '',
        name: '',
        providerId: '',
        connectivityStatus: 0,
        requestStatus: 0
    };
    public async created() {
        this.User = await this.userService.getUser();
        this.userRole = this.GetUserRole();
        this.$emit('childinit', this.layoutSup);
    }
    public GetUserRole() {
        return this.userRoleService.GetUserRole(this.User);
    }
    public async mounted() {
        const response = await this.shortService.getHistory(this.filterModel.operator,
            this.filterModel.name, this.filterModel.connectivityStatus, this.filterModel.requestStatus);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.shorts = response.result;
        this.shortsCount = response.totalCount;
        await this.isProviderDissconected();
    }
    public async isProviderDissconected() {
        const response = await this.providerStatusService.isProviderDissconected();
        this.providerDissconected = response.result;
    }
    @Watch('filterModel', { deep: true })
    public async search() {
        const response = await this.shortService.getHistory(this.filterModel.operator.toString(),
            this.filterModel.name, this.filterModel.connectivityStatus, this.filterModel.requestStatus);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.shorts = response.result;
        this.shortsCount = response.totalCount;
    }

    public getOperatorText(operatorId: number) {
        switch (operatorId) {
            case 1:
                return this.$t('operator_Zain').toString();
            case 2:
                return this.$t('operator_STC').toString();
            case 3:
                return this.$t('operator_Mobily').toString();
            default:
                return '-';
        }
    }
    public getConnectivityStatusText(connectivityStatus: number) {
        switch (connectivityStatus) {
            case 1:
                return this.$t('bulkshortPage.pendingAddition').toString();
            case 2:
                return this.$t('bulkshortPage.added').toString();
            default:
                return '-';
        }
    }

    public connectivityStatusOptions: any[] = [
        {
            id: 1,
            name: this.$t('bulkshortPage.pendingAddition').toString(),
        },
        {
            id: 2,
            name: this.$t('bulkshortPage.added').toString(),
        },
    ];

    public requestStatusOptions: any[] = [
        {
            id: 1,
            name: this.$t('bulkshortPage.inProgress').toString(),
        },
        {
            id: 2,
            name: this.$t('bulkshortPage.completed').toString(),
        },
    ];

    public getRequestStatusText(connectivityStatus: number) {
        switch (connectivityStatus) {
            case 1:
                return this.$t('bulkshortPage.inProgress').toString();
            case 2:
                return this.$t('bulkshortPage.completed').toString();
            default:
                return '-';
        }
    }
    public getShortCodeType(type: number) {
        switch (type) {
            case 1:
                return this.$t('White List').toString();
            case 2:
                return this.$t('Black List').toString();
            default:
                return '-';
        }
    }
    public getRate() {
        return (this.details.senderLeft / this.details.senderLeft) * 100;
    }

    public isEqual(value1: string | null, value2: string) {
        return (value1 === '' || value1 === null || value1 === undefined || value2.includes(value1));
    }

    public async showDetailsDialog(id: string) {
        this.shortCodeId = id;
        const responseShortCode = await this.shortService.getById(id);
        if (responseShortCode.isSuccess) {
            this.details = responseShortCode.result;
            var shortcodeRequest = this.shorts.find(s => s.id == id);
            if (shortcodeRequest != undefined) {
                this.details.operatotrName = this.getOperatorText(shortcodeRequest.operatorId);
                this.details.shortCode = shortcodeRequest.shortCode;
            }
            this.detailsDialog = true;
        } else {
            Toaster.error(responseShortCode.errorMessage);
        }
        this.currentBulkId = id;
        const responce = await this.shortService.getSkippedSenders(id, 0, 5);
        if (responce.isSuccess) {
            this.skippedModel = responce.result;
            this.skippedCount = responce.totalCount;
        } else {
            Toaster.error(responseShortCode.errorMessage);
        }
    }
    public async updatePaginate(val: any) {
        const responce = await this.shortService.getSkippedSenders(this.currentBulkId, val.page - 1, val.rowsPerPage);
        if (responce.isSuccess) {
            this.skippedModel = responce.result;
            this.skippedCount = responce.totalCount;
        } else {
            Toaster.error(responce.errorMessage);
        }
    }
    public hideDetailsDialog() {
        this.detailsDialog = false;
    }
    public sleep(ms: number) {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        });
    }
    public checkColor() {
        return '#0000FF';
    }
}