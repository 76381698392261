<div class="w-100 h-100">
    <div class="row" style="padding-top: 56px;">
        <div class="col-md-12">
            <div class="form-row justify-content-between align-items-baseline">
                <div class="col-auto">
                    <label class="header_text mb-3">
                        {{$t('extendDurationRequestsPage.extendDurationRequests')}}
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div class="row row-table">
        <div class="col-md-12">
            <div class="card card_layout">
                <div class="card-header">
                    <div class="form-row justify-content-between align-items-center w-100">
                        <div class="col-auto">
                            <label class="card_header_text">
                                <i class="fas fa-sync-alt reload" @click="reload()"></i>
                                {{$t('extendDurationRequestsPage.requests')}} ({{sendersCount}})
                            </label>
                        </div>
                        <div class="col-auto">
                            <button :disabled="!isAnySelected" class="btn btn-primary btn-action" @click="PreparePay('')">{{$t('extendDurationRequestsPage.pay')}}</button>
                            <button v-if="!providerDissconected" @click="checkRenewSender('')" class="btn btn-primary btn-action">{{$t('extendDurationRequestsPage.renew')}}</button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <template>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="input-group mb-3">
                                    <input class="form-control border-right-0 border-left"
                                           type="text"
                                           v-model="filterModel.senderName"
                                           @keypress="search"
                                           :placeholder="$t('dashboardPage.senderNameSearch')" />
                                    <div class="input-group-append">
                                        <span class="input-group-text right gray">
                                            <i class="fas fa-search"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <v-data-table v-model="selected"
                                      :headers="headers"
                                      :items="senders"
                                      :total-items="sendersCount"
                                      select-all
                                      :pagination.sync="pagination"
                                      :rows-per-page-text="$t('shared.rowsPerPage')"
                                      :rows-per-page-items='[10, 25, 50, 100]'
                                      @update:pagination="updatePaginate"
                                      item-key="id"
                                      class="elevation-1 table-striped">
                            <template v-slot:headers="props">
                                <tr>
                                    <th>
                                        <v-checkbox :input-value="props.all"
                                                    :indeterminate="props.indeterminate"
                                                    primary
                                                    hide-details
                                                    @click.stop="selectAll"></v-checkbox>
                                    </th>
                                    <th v-for="header in props.headers"
                                        :key="header.text"
                                        :class="['column', header.sortable ? 'sortable':'', header.sortable ? pagination.descending ? 'desc' : 'asc': '', header.value === pagination.sortBy ? 'active' : '']"
                                        @click="changeSort(header.value)" :width="header.width !== ''? header.width : '' ">
                                        <v-icon v-if="header.sortable" small>arrow_upward</v-icon>
                                        {{ header.text }}
                                    </th>
                                </tr>
                            </template>
                            <template v-slot:items="props">
                                <tr>
                                    <td>
                                        <v-checkbox v-model="props.selected"
                                                    @change="select($event, props.item.id)"
                                                    primary
                                                    hide-details></v-checkbox>
                                    </td>
                                    <td class="text-left">{{ props.item.id }}</td>
                                    <td class="text-center">{{ props.item.senderName }}</td>
                                    <td class="text-center">{{ props.item.duration }}</td>
                                    <td class="text-center">{{ props.item.createdDate }}</td>
                                    <td class="text-center">{{ props.item.requestStatus }}</td>
                                    <td class="text-center" v-if="props.item.requestStatus =='Pending Payment'">
                                        <template>
                                            <v-menu offset-y open-on-hover>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn color="btn-primary"
                                                           v-on="on">
                                                        <div>{{$t('shared.actions')}}</div>
                                                        <i class="fas fa-chevron-down pl-4" style="vertical-align: sub;"></i>
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-tile @click="PreparePay(props.item.id)">
                                                        <v-list-tile-title class="white">{{$t('extendDurationRequestsPage.pay')}}</v-list-tile-title>
                                                    </v-list-tile>
                                                    <v-list-tile @click="showresendDialog(props.item.id)">
                                                        <v-list-tile-title class="white">{{$t('shared.edit')}}</v-list-tile-title>
                                                    </v-list-tile>
                                                </v-list>
                                            </v-menu>
                                        </template>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </template>
                </div>
            </div>
        </div>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="resendDialog" width="833">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.renewSender')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideresendDialog"></i>
                    </div>
                </v-card-title>
                <v-card-text>
                    <div class="row">
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.clientName')}}</label>
                                <input class="form-control" :disabled="true" v-model="getRenewSenderModel.clientName" type="text" />
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.customerType')}}</label>
                                <v-select v-model="getRenewSenderModel.customerType"
                                          :items="customerTypeList"
                                          :label="$t('shared.select')"
                                          item-text="name"
                                          item-value="id"
                                          :disabled="true"
                                          solo>
                                </v-select>
                            </div>
                        </div>
                        <div class="col-md-3 mb-3" v-if="getRenewSenderModel.customerType !== 0">
                            <div class="form-group">
                                <label v-if="getRenewSenderModel.customerType === 1">{{$t('shared.budgetNumber')}}</label>
                                <label v-if="getRenewSenderModel.customerType === 2">{{$t('shared.licenseNumber')}}</label>
                                <label v-if="getRenewSenderModel.customerType === 3">{{$t('shared.commercialRegistrationNumber')}}</label>
                                <label v-if="getRenewSenderModel.customerType === 4">{{$t('shared.internationalCommercialRegistrationNumber')}}</label>
                                <input class="form-control" :disabled="true" v-model="getRenewSenderModel.crNumber" type="text" />
                            </div>
                        </div>
                        <div class="col-md-3 mb-3" v-if="getRenewSenderModel.customerType === 2 ||getRenewSenderModel.customerType === 3">
                            <div class="form-group">
                                <label>{{$t('shared.enterpriseUnifiedNumber')}}</label>
                                <input class="form-control" :disabled="enterpriseUnifiedNumberDisabled" v-model="getRenewSenderModel.enterpriseUnifiedNumber" type="text" />
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.representativeName')}}</label>
                                <input class="form-control" v-model="getRenewSenderModel.representativeName" type="text" :disabled="true" />
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.phoneNumber')}}</label>
                                <div v-if="getRenewSenderModel.customerType === 0 || getRenewSenderModel.customerType === 1 || getRenewSenderModel.customerType === 2 ||getRenewSenderModel.customerType === 3" class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text gray">
                                            +966
                                        </span>
                                    </div>
                                    <input class="form-control border-left-0" @paste="isNumberFieldPaste($event)" maxlength="9" @keypress="isNumberField($event)" v-model="getRenewSenderModel.representativeMobile" type="text" :disabled="true" />
                                </div>
                                <div v-if="getRenewSenderModel.customerType === 4" class="input-group mb-3">
                                    <input class="form-control border-left-0" @paste="isNumberFieldPaste($event)" @keypress="isNumberField($event)" v-model="getRenewSenderModel.representativeMobile" type="text" :disabled="true" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.representativeEmail')}}</label>
                                <input class="form-control" v-model="getRenewSenderModel.representativeEmail" type="text" :disabled="true" />
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.durationYear')}}</label>
                                <v-select v-model="updateExtendDurationRequestsModel.duration"
                                          :items="durationList"
                                          item-text="name"
                                          item-value="id"
                                          :label="$t('shared.select')"
                                          solo>
                                </v-select>
                            </div>
                        </div>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hideresendDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="saveExtendRequest">{{$t('shared.save')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</div>

