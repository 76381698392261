<div class="w-100 h-100">
    <div class="row" style="padding-top: 25px;padding-bottom: 25px;">
        <div class="col-md-12">
            <div class="form-row justify-content-between align-items-baseline">
                <div class="col-auto">
                    <label class="header_text mb-3">
                        {{$t('providerPage.providersList')}}
                    </label>
                </div>
                <div class="col-auto">
                    <router-link to="/Providers/ProviderRequests" tag="button" class="btn btn-primary"><span class="text_sm_hidden">{{$t('shared.providerRequests')}}</span><i class="fas fa-id-card-alt px-2"></i></router-link>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card card_layout">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="input-group mb-3">
                                <input class="form-control border-right-0 border-left"
                                       type="text"
                                       v-model="filterModel.name"
                                       :placeholder="$t('shared.providerName')" />
                                <div class="input-group-append">
                                    <span class="input-group-text right gray">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group mb-3">
                                <input class="form-control border-right-0 border-left"
                                       type="text"
                                       v-model="filterModel.crNumber"
                                       :placeholder="$t('shared.crNumber')" />
                                <div class="input-group-append">
                                    <span class="input-group-text right gray">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group mb-3">
                                <input class="form-control border-right-0 border-left"
                                       v-model="filterModel.accreditationNumber"
                                       type="text" :placeholder="$t('shared.accreditationNumber')" />
                                <div class="input-group-append">
                                    <span class="input-group-text right gray">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group mb-3">
                                <input class="form-control border-right-0 border-left"
                                       v-model="filterModel.taxNumber"
                                       type="text" :placeholder="$t('shared.taxNumber')" />
                                <div class="input-group-append">
                                    <span class="input-group-text right gray">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <template>
                        <v-data-table :headers="headers"
                                      :items="providers"
                                      :total-items="providersCount"
                                      :rows-per-page-text="$t('shared.rowsPerPage')"
                                      :rows-per-page-items='[10, 25, 50, 100]'
                                      @update:pagination="updatePaginate"
                                      class="elevation-1 table-striped">
                            <template v-slot:items="props">
                                <tr>
                                    <td class="text-center">{{ props.item.name }}</td>
                                    <td class="text-center">{{ props.item.crNumber }}</td>
                                    <td class="text-center">{{ props.item.accreditationNumber }}</td>
                                    <td class="text-center">{{ props.item.taxNumber }}</td>
                                    <td class="text-center">{{ props.item.state }}</td>
                                    <td class="text-center" v-if="userRole === 9">
                                        <v-switch v-model="!props.item.isDisconnected" @click.stop="showDissconectDialog($event,props.item.id,props.item.name,props.item.isDisconnected)"></v-switch>
                                    </td>
                                    <td class="text-center">
                                        <v-switch v-model="props.item.isLocked" @click="showLockDialog($event,props.item.id,props.item.isLocked)"></v-switch>
                                    </td>
                                    <td class="text-center">
                                        <router-link :to="'/Providers/EditProvider/' + props.item.id" tag="i" class="fas fa-pencil-alt Icon_Edit"></router-link>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </template>
                </div>
            </div>
        </div>
    </div>
    <v-dialog v-model="dissconectDialog" persistent width="554">
        <v-card>
            <v-card-title class="headline grey lighten-2" primary-title>
                <label class="login_header">
                    {{$t('shared.shortCodeLinking')}}
                </label>
                <div class="close__dialog">
                    <i class="fas fa-times" @click="hideDissconectDialog"></i>
                </div>
            </v-card-title>
            <v-card-text class="text-center">
                <label v-if="isDissconected" class="w-100 text-center text_body">
                    {{$t('providerPage.confirmDisconnecting')}} <br />
                </label>
                <label v-if="!isDissconected" class="w-100 text-center text_body">
                    {{$t('providerPage.confirmConnecting')}} <br />
                </label>
                <div class="row row_input pb-4">
                    <div class="col-md-12">
                        <div class="form-group">
                            <div class="input-group mb-3">
                                <input type="text" class="form-control text-center" v-model="otp" maxlength="6" @keypress="isNumberField($event)" />
                            </div>
                        </div>
                    </div>
                </div>
                <label  v-if="countDown >= 0" class="w-100 text-center text_body">
                    {{$t('providerPage.otpReminingTime')}}: {{ countDown }}
                </label>
                <label v-if="countDown < 0" class="w-100 text-center text_body">
                    {{$t('providerPage.otpReminingTime')}}:  0 
                </label>
                <v-card-actions class="row_input row_button form-row justify-content-center align-items-center py-0">
                    <div class="form-row justify-content-center align-items-center">
                        <div class="col-auto">
                            <button class="btn btn-primary" :disabled="isResendValid" @click="sendOtp">{{$t('loginPage.resendOtp')}}</button>
                            <button class="btn btn-primary" :disabled="!isResendValid" @click="verfiyOTP">{{$t('shared.submit')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card-text>
        </v-card>
    </v-dialog>
    <v-dialog v-model="lockDialog" persistent width="554">
        <v-card>
            <v-card-title class="headline grey lighten-2" primary-title>
                {{$t('shared.lockProviderInput')}}
                <div class="close__dialog">
                    <i class="fas fa-times" @click="hideLockDialog"></i>
                </div>
            </v-card-title>
            <v-card-text class="text-center">
                <p class="mt-3" v-if="isLocked">
                    {{$t('shared.lockProviderInputText')}}
                </p>
                <p class="mt-3" v-if="!isLocked">
                    {{$t('shared.unlockProviderInputText')}}
                </p>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <div class="form-row justify-content-end align-items-center">
                    <div class="col-auto mx-3">
                        <a class="link_danger" @click="hideLockDialog">
                            {{$t('shared.no')}}
                        </a>
                    </div>
                    <div class="col-auto">
                        <button class="btn btn-danger px-5 py-2" @click="switchLock">{{$t('shared.yes')}}</button>
                    </div>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <div class="text-xs-center">
        <v-dialog v-model="deleteDialog" persistent width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.deleteInput')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideDeleteDialog"></i>
                    </div>
                </v-card-title>

                <v-card-text class="text-center">
                    <img :src="require('../img/folder.svg')" />
                    <p class="mt-3">
                        {{$t('shared.deleteInputText')}}
                    </p>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hideDeleteDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="deleteProvider">{{$t('shared.delete')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</div>
