<div class="w-100 h-100">
    <div class="row d-flex justify-content-center">
        <div class="col-md-12">
            <div class="form-row justify-content-between align-items-baseline">
                <div class="col-auto">
                    <label class="header_text mb-3">
                        {{$t('shared.updateContractRequest')}}
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-sm-center">
        <div class="col-sm-8">
            <v-stepper alt-labels :value="stepNumber">
                <v-stepper-header>
                    <v-stepper-step step="1" :class="{'complete': stepNumber > 1}">{{$t('shared.clientInfo')}}</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="2" :class="{'complete': stepNumber > 2}">{{$t('shared.contractInfo')}}</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="3" :class="{'complete': stepNumber > 3}">{{$t('shared.reviewRequest')}}</v-stepper-step>
                </v-stepper-header>
            </v-stepper>
        </div>
    </div>
    <!-- Step 11111111111 -->
    <div class="row" v-if="stepNumber == 1">
        <div class="col-md-12 pr-0">
            <div class="card card_layout">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>{{$t('shared.customerType')}} </label>
                                <v-select v-model="model.customerType" :items="customerTypeList" :label="$t('shared.select')" disabled
                                          item-text="name" item-value="id" solo>
                                </v-select>
                            </div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <div class="form-group">
                                <label v-if="model.customerType === 1">{{$t('shared.budgetNumber')}} </label>
                                <label v-if="model.customerType === 2">{{$t('shared.licenseNumber')}}</label>
                                <label v-if="model.customerType === 3">{{$t('shared.commercialRegistrationNumber')}}</label>
                                <label v-if="model.customerType === 4">{{$t('shared.internationalCommercialRegistrationNumber')}}</label>
                                <input class="form-control" v-model="model.crNumber" type="text" disabled />
                            </div>
                        </div>
                        <div class="col-md-4 mb-3" v-if="model.customerType === 2 ||model.customerType === 3 ">
                            <div class="form-group">
                                <label>{{$t('shared.enterpriseUnifiedNumber')}}</label>
                                <input class="form-control" v-model="model.enterpriseUnifiedNumber" type="text" disabled />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="stepNumber == 1">
                    <div class="col-md-12 pr-0">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{$t('dashboardPage.senderName')}} </label>

                                        <input class="form-control" v-model="model.senderName" type="text" disabled />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="form-row justify-content-between align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_light" @click="reset" v-if="stepNumber == 2"> </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-outline-primary px-5 py-2 mx-2" @click="back" v-if="stepNumber > 1">
                            </button>
                            <button class="btn btn-primary px-5 py-2" @click="next">
                                {{$t('shared.next')}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Step 22222222222 -->
    <div class="row" v-if="stepNumber == 2">
        <div class="col-md-12 pr-0">
            <div class="card card_layout">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>{{$t('shared.attachContract')}}</label>
                                <div class="input-group mb-3">
                                    <input class="form-control border-right-0 border-left" readonly v-model="contractAttachmentName"
                                           type="text" />
                                    <input type="file" ref="contractAttachment" hidden accept=".png, .jpg, .jpeg, .pdf, .docx"
                                           @change="onFilePicked" />
                                    <div class="input-group-prepend">
                                        <span class="input-group-text right" style="padding: 2px 2px 0px 2px !important;">
                                            <i v-if="contractAttachmentName !== ''" class="far fa-trash-alt Icon_delete"
                                               @click="deleteContract()"></i>
                                            <button class="btn btn-primary" @click="onPickFile('51643945-0f7c-4ab0-a257-00e3e45513d9')">
                                                {{$t('shared.attach')}}
                                            </button>
                                        </span>
                                    </div>

                                </div>
                            </div>
                            <div class="col-md-8 col-10">
                                <div class="form-group">
                                    <label>{{$t('shared.theMaximumSizeForTheAttachmentIs3Megabytes')}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="input-group mb-3">
                                <label>{{$t('shared.expiryDate')}}</label>
                                <v-menu v-model="expiryDateFromMenu" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="input-group mb-3">
                                            <input class="form-control border-right-0 border-left" v-model="model.expiryDate" readonly
                                                   v-bind="attrs" ref="createdDatePicker" v-on="on" type="text" />
                                            <div class="input-group-prepend">
                                                <span class="input-group-text right gray">
                                                    <i class="far fa-calendar-alt"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </template>
                                    <v-date-picker v-model="model.expiryDate" no-title scrollable @input="expiryDateFromMenu = false">
                                    </v-date-picker>
                                </v-menu>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="form-row justify-content-between align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_light" @click="reset" v-if="stepNumber == 2">{{$t('shared.reset')}}</a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-outline-primary px-5 py-2 mx-2" @click="back" v-if="stepNumber > 1">
                                {{$t('shared.back')}}
                            </button>
                            <button class="btn btn-primary px-5 py-2" @click="next" :disabled="!isValidStep" v-if="stepNumber < 3">
                                {{$t('shared.next')}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Step 3333333 -->
    <div class="row" v-if="stepNumber >2">
        <div class="col-md-12 ">
            <div class="card card_layout">
                <div class="box_conferm" v-if="stepNumber == 4">
                    <img class="pb-4" :src="require('../../img/BigCheck.svg')" />
                    <label class="w-100 pb-4">{{$t('shared.requestSubmittedSuccessfully')}}</label>
                    <div class="form-row justify-content-center align-items-center">
                        <div class="col-auto mx-3">
                            <router-link to="/ConnectingRequestDashboard" tag="button"
                                         class="btn btn-outline-primary px-5 py-2 mx-2">{{$t('shared.requestsListPage')}}</router-link>
                        </div>
                        <div class="col-auto mx-3">
                            <button class="btn btn-primary px-5 py-2" @click="newRequest()">
                                {{$t('shared.newRequest')}}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3 mb-3">
                            <label class="user_info_header mb-0">{{$t('shared.clientName')}}</label>
                            <h4 class="user_info_txt">{{model.clientName}}</h4>
                        </div>
                        <div class="col-md-3 mb-3">
                            <label class="user_info_header mb-0">{{$t('shared.customerType')}}</label>
                            <h4 class="user_info_txt">
                                {{getCustomerTypeText(model.customerType)}}
                            </h4>
                        </div>
                        <div class="col-md-3 mb-3">
                            <label class="user_info_header mb-0" v-if="model.customerType === 1">{{$t('shared.budgetNumber')}}</label>
                            <label class="user_info_header mb-0"
                                   v-if="model.customerType === 2">{{$t('shared.licenseNumber')}}</label>
                            <label class="user_info_header mb-0"
                                   v-if="model.customerType === 3">{{$t('shared.commercialRegistrationNumber')}}</label>
                            <label class="user_info_header mb-0"
                                   v-if="model.customerType === 4">{{$t('shared.internationalCommercialRegistrationNumber')}}</label>
                            <h4 class="user_info_txt">{{model.crNumber}}</h4>
                        </div>
                        <div class="col-md-3 mb-3">
                            <label class="user_info_header mb-0">{{$t('shared.senderName')}}</label>
                            <h4 class="user_info_txt">{{model.senderName}}</h4>
                        </div>

                        <div class="col-md-3 mb-3">
                            <label class="user_info_header mb-0">{{$t('shared.senderType')}}</label>
                            <h4 class="user_info_txt">
                                <span v-for="(type, index) in senderType" :key="type">
                                    {{getSenderTypeText(type)}}
                                </span>
                            </h4>
                        </div>
                        <div class="col-md-3 mb-3">
                            <label class="user_info_header mb-0">{{$t('shared.expiryDate')}}</label>
                            <h4 class="user_info_txt">{{model.expiryDate}}</h4>
                        </div>
                        <div class="col-md-3 mb-3">
                            <label class="user_info_header">{{$t('shared.attachDelegationLetter')}}</label>
                            <h4 class="user_info_txt">
                                <span class="badge badge-primary">
                                    <i class="fas fa-paperclip"></i>{{model.contractAttachment.fileName}}
                                </span>
                            </h4>
                        </div>
                    </div>
                </div>
                <br>
                <br>
                <div class="card-footer">
                    <div class="form-row justify-content-between align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_light" @click="reset" v-if="stepnumber == 1"> </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-outline-primary px-5 py-2 mx-2" @click="back" v-if="stepNumber > 1">
                                {{$t('shared.back')}}
                            </button>
                            <button class="btn btn-primary px-5 py-2" @click="next" :disabled="!isValidStep" v-if="stepNumber < 3">
                                {{$t('shared.next')}}
                            </button>
                            <button class="btn btn-primary px-5 py-2" v-if="stepNumber == 3" @click="showsubmitRequestDialog">
                                {{$t('shared.submit')}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- submit modal  -->
    <div class="text-xs-center">
        <v-dialog v-model="submitRequestdialog" width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.editInput')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hidesubmitRequestDialog"></i>
                    </div>
                </v-card-title>
                <v-card-text class="text-center">
                    <p class="mt-3">
                        {{$t('shared.updateContractRequestInputText')}}
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hidesubmitRequestDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="submitRequest">
                                {{$t('shared.Ok')}}
                            </button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</div>