import AxiosService from '@/Services/AxiosService';
import LoaderService from '@/Services/LoaderService';
import { Inject, Service } from 'vue-di-container';
import { AddConectivityModel } from './addContractConnectivityModel';

@Service()
export default class ContractConnectivityDetailService {
    @Inject(AxiosService) public axiosService!: AxiosService;
    @Inject(LoaderService) public loaderService!: LoaderService;
    public async get(senderId: string): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/sender-conectivity-detail?senderId=' + senderId;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }

    public async getSenderInfo(senderId: string): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/senders/' + senderId;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async isProviderDissconected(): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/providers/is-provider-dissconected';
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async getLogDetails(id: string): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/sender-conectivity-detail-log?connectivityDetailsId=' + id;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async getConnectivityConfigration(): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/sender-conectivity-detail/connectivity-details-configration';
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }


    public async post(model: AddConectivityModel) {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/sender-conectivity-detail';
            const result = await this.axiosService.axiosInstance.post(url, model);
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return error;
        }
    }
    public async markAsPendingAddition(id: string) {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/sender-conectivity-detail/mark-as-pending-addition?senderConectivityDetailId=' + id;
            const result = await this.axiosService.axiosInstance.put(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return error;
        }
    }
    public async delete(senderConectivityDetailId: string) {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/sender-conectivity-detail';
            const result = await this.axiosService.axiosInstance.delete(url, {
                params: {
                    senderConectivityDetailId,
                },
            });
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return error;
        }
    }
}
