import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import WithRender from './ContractRequestInfo.html';
import { Inject } from 'vue-di-container';
import Progress from 'easy-circular-progress';
import contractRequestInfoService from './ContractRequestInfoService';
import UserService from '../../../shared/userService/UserService';
import { AttachmentModel } from '../../AddRequest/AttachmentModel';
import FileSaver from 'file-saver';
import { Toaster } from '../../../Services/toast';
import moment from 'moment';
import { SenderType } from '../../../shared/Enums/SenderType';
import UserRoleService from '../../../shared/userService/UserRoleService';
import { SenderRequestApprovalHistoryViewModel } from './SenderRequestApprovalHistoryViewModel';
import { SenderRequestExtendViewModel } from './SenderRequestExtendViewModel';
import ContractRequestInfoService from './ContractRequestInfoService';
import { ContractRequestModel } from './ContractRequestModel';
import ConnectingRequestDashboardService from '../ConnectingRequestDashboardService';
import { RejectionReasonModel } from '../../RejectionReason/RejectionReasonModel';

@WithRender
@Component({
    components: {
        Progress,
    }, diProvide: [
        ConnectingRequestDashboardService,
        UserService,
        contractRequestInfoService,
        UserRoleService,
    ],
})
export default class ContractRequestInfo extends Vue {
    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;
    @Prop({ type: Boolean, default: false })
    public isFromDashboard: boolean | any;
    @Prop({ type: String, default: '' })
    public id: string | any;
    public resendComment: string = '';
    public rejectResone: string | null = null;
    public otherRejectResone: string = ''
    public tenantId: string | any;
    public selectedRejectReason: RejectionReasonModel = {} as RejectionReasonModel;
    public selectedRejectReasonList: RejectionReasonModel[] = {} as RejectionReasonModel[];
    @Inject(ConnectingRequestDashboardService) public contractDashboardService!: ConnectingRequestDashboardService;
    @Inject(ContractRequestInfoService) public contractRequestInfoService!: ContractRequestInfoService;
    @Inject(UserService) public userService!: UserService;
    @Inject(UserRoleService) public userRoleService!: UserRoleService;
    public sender: ContractRequestModel = {
        id: '',
        duration: '',
        senderType: 0,
        newSenderType: 0,
        senderStatus: 0,
        senderId: '',
        startDate: '',
        clientName: '',
        crNumber: '',
        enterpriseUnifiedNumber: '',
        providerName: '',
        customerType: 0,
        contractAttachment: null,
        senderName: '',
        requestStatus: 0,
        lastRejectionReason: '',
        remainingDays: 0,
        totalDays: 0,
        comment: '',
        isCriticalSender: false,
        expiryDate: '',
        tenantId: '',
        senderExpiryDate: '',
        automaticallyInserted: false,
    };
    public isItOtherReasonPorp: boolean = false;
    public providerLocked: boolean = false;
    public rejectdialog: boolean = false;
    public showSenderRequestTransactionsdialog: boolean = false;
    public showExtendRequestsdialog: boolean = false;
    public isAllSelected: boolean = true;
    public isOtherSelected: boolean = false;
    public deactivateDialog: boolean = false;
    public approveDialog: boolean = false;
    public resendDialog: boolean = false;
    public senderType: SenderType[] = [];
    public SenderRequestTransactions: SenderRequestApprovalHistoryViewModel[] = [];
    public senderRequestExtendViewModel: SenderRequestExtendViewModel[] = [];
    public selectedIds: string[] = [];
    public userRole: any;
    public customerType: string = '';
    public popUpRejectionReasons: RejectionReasonModel[] = [];
    public approveComment: string = '';
    public User: any;
    public headers: any = [
        { text: this.$t('senderInfo.requestStatus'), value: 'requestStatus', align: 'center', sortable: false },
        { text: this.$t('senderInfo.createdDate'), value: 'createdDate', align: 'center', sortable: false },
        { text: this.$t('senderInfo.comment'), value: 'rejectionReason', align: 'center', sortable: false },
    ];
    public extendRequestHeaders: any = [
        { text: this.$t('senderInfo.extendRequestId'), value: 'extendRequestId', align: 'center', sortable: false },
        { text: this.$t('senderInfo.duration'), value: 'duration', align: 'center', sortable: false },
        { text: this.$t('senderInfo.requestStatus'), value: 'requestStatus', align: 'center', sortable: false },
        { text: this.$t('senderInfo.createdDate'), value: 'createdDate', align: 'center', sortable: false },
    ];
    public async mounted() {
        this.selectedIds.push(this.id);
        this.$emit('childinit', this.layoutSup);
        this.User = await this.userService.getUser();
        this.userRole = this.userRoleService.GetUserRole(this.User);
        if (this.userRole === 2 || this.userRole === 5) {
            this.isProviderLocked();
        }
        this.sender = await this.contractRequestInfoService.GetById(this.id, this.isFromDashboard);
        this.userRole = this.GetUserRole();
        this.tenantId = this.User.details.TenantId;
        this.customerType = this.getCustomerTypeText(this.sender.customerType);
        this.getSenderType();
        if (this.userRole === 1 || this.userRole === 9) {
            await this.getRejectionReasons();
        }
        this.selectAllToggle();
    }
    public async isProviderLocked() {
        const response = await this.contractDashboardService.isProviderLocked();
        this.providerLocked = response.result;
    }
    public GetUserRole() {
        return this.userRoleService.GetUserRole(this.User);
    }
    public SelectOtherToggle() {
        this.$nextTick(() => {
            if (this.isOtherSelected) {
                this.otherRejectResone = ''
                this.isOtherSelected = false;
                this.isItOtherReasonPorp = false;
            } else {
                this.isOtherSelected = true;
                this.isItOtherReasonPorp = true;
            }
        });
    }
    public get isItOtherReason(): boolean {
        return this.isItOtherReasonPorp;
    }
    public async getRejectionReasons() {
        const response = await this.contractDashboardService.getSenderNameRejectReasons(2);
        this.popUpRejectionReasons = response.result;
    } public get rejectReasonIsValid(): boolean {
        return ((this.selectedRejectReasonList.length !== 0 && this.selectedRejectReasonList.length !== undefined) || this.otherRejectResone !== '');
    }
    public get icon() {
        if (this.isAllSelected && this.selectedRejectReasonList.length === this.popUpRejectionReasons.length) {
            return 'check_box';
        }
        if (this.selectedRejectReasonList.length > 0) {
            return 'indeterminate_check_box';
        }
        return 'check_box_outline_blank';
    }
    public get OtherIcon() {
        if (this.isOtherSelected) {
            return 'check_box';
        }
        return 'check_box_outline_blank';
    }
    public selectAllToggle() {
        this.$nextTick(() => {
            if (this.isAllSelected) {
                this.selectedRejectReasonList = [];
                this.rejectResone = null;
                this.isAllSelected = false;
            } else {
                this.isAllSelected = true;
                this.rejectResone = this.popUpRejectionReasons.slice().map((x) => x.rejectionText).toString();
                this.selectedRejectReasonList = this.popUpRejectionReasons.slice();
            }
        });
    }


    public getCustomerTypeText(typeId: number) {
        switch (typeId) {
            case 1:
                return this.$t('shared.government').toString();
            case 2:
                return this.$t('shared.charity').toString();
            case 3:
                return this.$t('shared.private').toString();
            case 4:
                return this.$t('shared.international').toString();
            default:
                return '-';
        }
    }

    public getRequestTypeText(typeId: number) {
        switch (typeId) {
            case 7:
                return this.$t('New Contract').toString();
            case 8:
                return this.$t('Renew Contract').toString();
            default:
                return '-';
        }
    }

    public getSenderType() {
        const senderBinary = this.sender.senderType;
        if ((senderBinary & 1) === 1) {
            this.senderType.push(1);
        }
        if ((senderBinary & 2) === 2) {
            this.senderType.push(2);
        }
        if ((senderBinary & 4) === 4) {
            this.senderType.push(4);
        }
        if ((senderBinary & 8) === 8) {
            this.senderType.push(8);
        }
    }

    public getSenderTypeText(typeId: number) {
        switch (typeId) {
            case SenderType.Advertisement:
                return this.$t('shared.advertisement').toString();
            case SenderType.Service:
                return this.$t('shared.service').toString();
            case SenderType.Awareness:
                return this.$t('shared.awareness').toString();
            case SenderType.Warning:
                return this.$t('shared.warning').toString();
            default:
                return '-';
        }
    }

    public checkColor(typeStatus: number) {
        switch (typeStatus) {
            case 1: {
                return '#c427ba';
                break;
            }
            case 2: {
                return '#5aaa0b';
                break;
            }
            case 3: {
                return '#C42727';
                break;
            }
            case 4: {
                return '#aaa7a7';
                break;
            }
        }
    }
    public GetSenderStatus(typeStatus: number) {
        switch (typeStatus) {
            case 1: {
                return 'Pending Approval';
                break;
            }
            case 2: {
                return 'Approved';
                break;
            }
            case 3: {
                return 'Rejected';
                break;
            }
            case 4: {
                return 'Paid';
                break;
            }
        }
    }

    public getRate(startDate: string, duration: string) {
        return (this.remainingDays(startDate, duration) / (parseInt(duration, 10) * 365)) * 100;
    }

    public remainingDays(startDate: string, duration: string): number {
        const todaysdate = moment();
        const expiryDate = moment(startDate).add(parseInt(duration, 10), 'years').calendar();
        const eventdate = moment(expiryDate);
        if (moment(moment(startDate).format('L')).isAfter(moment().format('L'))) {
            return eventdate.diff(moment(startDate), 'days') + 1;
        } else {
            return eventdate.diff(todaysdate, 'days') + 1;
        }
    }

    public expiryDate(startDate: string, duration: string): number {
        const expiryDateString = moment(startDate).add(parseInt(duration, 10), 'years').calendar();
        const eventdate = moment(startDate);
        const expiryDate = moment(expiryDateString);
        return expiryDate.diff(eventdate, 'days') + 1;
    }
    public showresendDialog() {
        this.resendDialog = true;
    }
    public hideresendDialog() {
        this.resendComment = '';
        this.resendDialog = false;
    }
    public showapproveDialog() {
        this.approveComment = '';
        this.approveDialog = true;
    }
    public hideapproveDialog() {
        this.approveDialog = false;
    }
    public async approve() {
        if (this.sender.requestStatus !== 1) {
            Toaster.error(this.$t('dashboardPage.NotAllSenderPendingApproval').toString());
            return;
        }
        else {
            const response = await this.contractDashboardService.approve(this.selectedIds, this.approveComment);
            if (!response.isSuccess) {
                Toaster.error(response.errorMessage);
            } else {
                Toaster.success(this.$t('dashboardPage.Approvedsuccessfully').toString());
                await this.contractDashboardService.contractApproveEmail(this.selectedIds);
                this.$router.go(0);
            }
        }
    }
    public async resend() {
        if (this.sender.requestStatus !== 3) {
            Toaster.error(this.$t('dashboardPage.NotAllSenderRejected').toString());
            return;
        } else {
            const response = await this.contractDashboardService.resend(this.selectedIds, this.resendComment);
            if (!response.isSuccess) {
                Toaster.error(response.errorMessage);
            } else {
                Toaster.success(this.$t('dashboardPage.resentSuccessfully').toString());
                await this.contractDashboardService.resendContractRequestEmail(this.selectedIds);
                this.selectedIds = [];
                this.$router.go(0);
            }
        }
    }

    public ConectivityDetail(senderId: string) {
        this.$router.push({ name: 'SenderConectivityDetail', params: { senderId } });
    }
    public async hideRejectDialog() {
        this.isAllSelected = false;
        this.isOtherSelected = false;
        this.rejectResone = null;
        this.otherRejectResone = '';
        this.isItOtherReasonPorp = false;
        this.selectedRejectReasonList = [];
        this.rejectdialog = false;
    }
    public async showRejectDialog() {
        this.rejectdialog = true;
    }
    public async reject() {
        this.rejectdialog = false;
        if (this.sender.requestStatus !== 1) {
            Toaster.error(this.$t('dashboardPage.NotAllSenderPendingApproval').toString());
            return;
        } else {
            if (this.selectedRejectReasonList.length !== undefined) {
                this.rejectResone = this.selectedRejectReasonList.slice().map((x) => x.rejectionText).toString();
            }
            this.rejectResone = (this.rejectResone === null || this.rejectResone === '') ? this.otherRejectResone : this.otherRejectResone != '' ? this.rejectResone + ',' + this.otherRejectResone : this.rejectResone;
            await this.contractDashboardService.reject(this.selectedIds, this.rejectResone);
            await this.contractDashboardService.rejectContractRequestEmail(this.selectedIds);
            this.rejectResone = '';
            this.selectedIds = [];
            Toaster.success(this.$t('dashboardPage.Rejectedsuccessfully').toString());
            this.$router.go(0);
        }
    }
    //public async reject() {
    //    this.rejectdialog = false;
    //    if (this.sender.requestStatus !== 1) {
    //        Toaster.error(this.$t('dashboardPage.NotAllSenderPendingApproval').toString());
    //        return;
    //    } else {
    //        const response = await this.dashboardService.reject(this.selectedIds, this.rejectResone);

    //        if (!response.isSuccess) {
    //            Toaster.error(response.errorMessage);
    //        } else {
    //            await this.dashboardService.rejectContractRequestEmail(this.selectedIds);
    //            this.rejectResone = '';
    //            this.selectedIds = [];
    //            Toaster.success(this.$t('dashboardPage.Rejectedsuccessfully').toString());
    //            this.$router.go(0);
    //        }
    //    }
    //}
    public async showSenderRequestTransactions() {
        var response = await this.contractRequestInfoService.GetRequestApprovalHistory(this.id);
        if (response.isSuccess) {
            this.SenderRequestTransactions = response.result;
            this.showSenderRequestTransactionsdialog = true;
        }
    }

    public async hideshowSenderRequestTransactionsdialog() {
        this.showSenderRequestTransactionsdialog = false;
    }

    public PreparePay() {
        if (this.sender.requestStatus !== 2) {
            Toaster.error(this.$t('dashboardPage.NotAllSenderPendingPay').toString());
            return;
        } else {
            this.$router.push({ name: 'Payment', params: { ids: this.selectedIds.join(',') } });
        }
    }
    public async DownloadFile(file: AttachmentModel) {
        const fileContent = await this.contractRequestInfoService.GetFileContent(file.id);
        const array = Buffer.from(fileContent, 'base64');
        const blob = new Blob([array], { type: 'application/pdf' });
        FileSaver.saveAs(blob, file.fileName);
    }
}
