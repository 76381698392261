import AxiosService from '@/Services/AxiosService';
import LoaderService from '@/Services/LoaderService';
import { Inject, Service } from 'vue-di-container';

@Service()
export default class ProviderStatusService {
    @Inject(AxiosService) public axiosService!: AxiosService;
    @Inject(LoaderService) public loaderService!: LoaderService;

    public async isProviderDissconected(): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/providers/is-provider-dissconected';
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
}
