<div class="w-100 h-100">
    <div class="row" style="padding-top: 25px;padding-bottom: 25px;">
        <div class="col-md-12">
            <div class="form-row justify-content-between align-items-baseline">
                <div class="col-auto">
                    <label class="header_text mb-3">
                        {{$t('shared.senderConectivityDetail')}} ( {{filterConnectivities.length}} )
                    </label>
                </div>
                <div v-if="!providerDissconected" class="col-auto">
                    <button class="btn btn-primary" @click="showAddConectivityDialog()"><span class="text_sm_hidden">{{$t('shared.addNewConectivity')}}</span><i class="fas fa-plus px-2"></i></button>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 pr-0">
            <div class="card card_layout">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-4 mb-3">
                            <label class="user_info_header mb-0">{{$t('dashboardPage.senderName')}}</label>
                            <h4 class="user_info_txt">{{senderInfo.senderName}}</h4>
                        </div>
                        <div class="col-sm-4 mb-3">
                            <label class="user_info_header mb-0">{{$t('shared.senderType')}}</label>
                            <h4 class="user_info_txt">
                                <span v-for="(type, index) in senderType" :key="type">
                                    {{getSenderTypeText(type)}}
                                </span>
                            </h4>
                        </div>
                        <div class="col-sm-4 mb-3">
                            <label class="user_info_header mb-0">{{$t('shared.clientName')}}</label>
                            <h4 class="user_info_txt">{{senderInfo.clientName}}</h4>
                        </div>
                        <div class="col-sm-4 mb-3">
                            <label class="user_info_header mb-0">{{$t('shared.customerType')}}</label>
                            <h4 class="user_info_txt">{{getCustomerTypeText(senderInfo.customerType)}}</h4>
                        </div>
                        <div class="col-sm-4 mb-3" v-if="senderInfo.customerType !== 4">
                            <label class="user_info_header mb-0" v-if="senderInfo.customerType === 1">{{$t('shared.budgetNumber')}}</label>
                            <label class="user_info_header mb-0" v-if="senderInfo.customerType === 2">{{$t('shared.licenseNumber')}}</label>
                            <label class="user_info_header mb-0" v-if="senderInfo.customerType === 3">{{$t('shared.commercialRegistrationNumber')}}</label>
                            <label class="user_info_header mb-0" v-if="senderInfo.customerType === 4">{{$t('shared.internationalCommercialRegistrationNumber')}}</label>
                            <h4 class="user_info_txt">{{senderInfo.crNumber}}</h4>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                <input class="form-control border-right-0 border-left"
                                       type="text"
                                       v-model="filterModel.operator"
                                       :placeholder="$t('shortPage.operatorSearch')" />
                                <div class="input-group-append">
                                    <span class="input-group-text right gray">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                <input class="form-control border-right-0 border-left"
                                       type="text"
                                       v-model="filterModel.shortCodeName"
                                       :placeholder="$t('shortPage.shortcodeSearch')" />
                                <div class="input-group-append">
                                    <span class="input-group-text right gray">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <v-select v-model="filterModel.status"
                                      :items="statusOptions"
                                      item-text="name"
                                      item-value="id"
                                      :clearable="true"
                                      @change="search()"
                                      :label="$t('shared.selectStatus')"
                                      solo>
                            </v-select>
                        </div>
                    </div>
                    <template>
                        <v-data-table :headers="headers"
                                      :items="filterConnectivities"
                                      hide-actions
                                      class="elevation-1 table-striped">
                            <template v-slot:items="props">
                                <tr>
                                    <td class="text-center">{{ getOperatorText(props.item.operator) }}</td>
                                    <td class="text-center">{{ props.item.shortCodeName }}</td>
                                    <td class="text-center">
                                        {{getSenderTypeTextTable(props.item.senderType)}}
                                    </td>
                                    <td class="text-center" :class="'status_' + props.item.status">{{ getConectivityStatusText(props.item.status) }}</td>
                                    <td class="text-center">
                                        <i v-if="props.item.isAlreadyExist === true && (props.item.status === 2)" class="fas fa-redo-alt Icon_Edit" @click="showResendToOperatorDialog(props.item.id,props.item.status)"></i>
                                    </td>
                                    <td class="text-center">
                                        <i class="far fa-trash-alt Icon_delete" v-if="userRole === 5 && (props.item.status === 1 || props.item.status === 2)" @click="showDeleteDialog(props.item.id)"></i>
                                        <i class="fas fa-info-circle Icon_Info" @click="showlogDialog(props.item.id)"></i>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </template>
                </div>
            </div>
        </div>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="resendToOperatorDialog" persistent width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.resendToOperator')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideResendToOperatorDialog"></i>
                    </div>
                </v-card-title>
                <v-card-text class="text-center">
                    <img :src="require('../img/folder.svg')" />
                    <p class="mt-3">
                        {{$t('shared.resendToOperatorInputText')}}
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hideResendToOperatorDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="resendToOperator">{{$t('shared.resend')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="deleteDialog" persistent width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.deleteInput')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideDeleteDialog"></i>
                    </div>
                </v-card-title>

                <v-card-text class="text-center">
                    <img :src="require('../img/folder.svg')" />
                    <p class="mt-3">
                        {{$t('shared.deleteInputText')}}
                    </p>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hideDeleteDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="deleteConectivity">{{$t('shared.delete')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="addDialog" persistent width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.addNewConectivity')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideAddDialog"></i>
                    </div>
                </v-card-title>

                <v-card-text>
                    <div class="row" v-if="!isModeShow">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>{{$t('shared.senderType')}}</label>
                                <v-select v-model="addModel.senderType"
                                          :items="senderTypeOptions"
                                          item-text="name"
                                          item-value="id"
                                          :label="$t('shared.select')"
                                          solo>
                                </v-select>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>{{$t('shared.providerName')}}</label>
                                <v-select v-model="addModel.operator"
                                          :items="operatorOptions"
                                          item-text="name"
                                          item-value="id"
                                          :label="$t('shared.select')"
                                          solo>
                                </v-select>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>{{$t('shortPage.shortCode')}}</label>
                                <template>
                                    <v-select v-model="addModel.shortCodeId"
                                              :items="shortCodeOptions"
                                              item-text="name"
                                              item-value="id"
                                              multiple
                                              :label="$t('shared.select')"
                                              solo>
                                        <template v-slot:prepend-item>
                                            <v-list-tile ripple
                                                         @click="selectAllToggle">
                                                <v-list-tile-action>
                                                    <i aria-hidden="true" class="v-icon material-icons theme--light">{{ icon }}</i>
                                                </v-list-tile-action>
                                                <v-list-tile-content>
                                                    <v-list-tile-title>{{$t('shared.selectAll')}}</v-list-tile-title>
                                                </v-list-tile-content>
                                            </v-list-tile>
                                            <v-divider class="mt-2"></v-divider>
                                        </template>
                                    </v-select>
                                </template>
                            </div>
                        </div>
                        <div v-if="isAlreadyExistEnabled" class="col-md-12">
                            <div class="form-group">
                                <div class="input-group mb-3">
                                    <label style="padding: 10px;">{{$t('shared.AlreadyActivated')}}</label>
                                    <label style="padding: 10px;">{{$t('shared.AlreadyActivatedAR')}}</label>
                                    <input type="radio" id="true" value="true" v-model="addModel.isAlreadyExist" />
                                    <label for="true" style="padding: 10px;">Yes</label>
                                    <input type="radio" id="false" value="false" v-model="addModel.isAlreadyExist" />
                                    <label for="false" style="padding: 10px;">No</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="isModeShow">
                        <div class="col-md-12 mb-2">
                            <h4>{{$t('shared.sureAddConnectivity')}}</h4>
                        </div>
                        <div class="col-md-12 mb-3">
                            <label class="user_info_header mb-0">{{$t('shared.senderType')}}</label>
                            <h4 class="user_info_txt">{{getSenderTypeTextTable(addModel.senderType)}}</h4>
                        </div>
                        <div class="col-md-12 mb-3">
                            <label class="user_info_header mb-0">{{$t('shortPage.companyName')}}</label>
                            <h4 class="user_info_txt">{{getOperatorText(addModel.operator)}}</h4>
                        </div>
                        <div class="col-md-12 mb-3">
                            <label class="user_info_header mb-0">{{$t('shortPage.shortCode')}}</label>
                            <h4 class="user_info_txt">
                                <span v-for="(itme, index) in addModel.shortCodeId" :key="type">
                                    {{shortCodeOptions.filter((x) => x.id === itme)[0].name}}
                                </span>
                            </h4>
                        </div>
                        <div v-if="isAlreadyExistEnabled" class="col-md-12 mb-3">
                            <div class="form-group">
                                <div class="input-group mb-3">
                                    <label style="padding: 10px;">{{$t('shared.AlreadyActivated')}}</label>
                                    <input disabled="true" type="radio" id="true" value="true" v-model="addModel.isAlreadyExist" />
                                    <label for="true" style="padding: 10px;">Yes</label>
                                    <input disabled="true" type="radio" id="false" value="false" v-model="addModel.isAlreadyExist" />
                                    <label for="false" style="padding: 10px;">No</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3" v-if="!isModeShow">
                            <a class="link_login" @click="hideAddDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto mx-3" v-if="isModeShow">
                            <a class="link_login" @click="back">
                                {{$t('shared.back')}}
                            </a>
                        </div>
                        <div class="col-auto" v-if="!isModeShow">
                            <button class="btn btn-primary px-5 py-2" @click="save" :disabled="!isValid">{{$t('shared.next')}}</button>
                        </div>
                        <div class="col-auto" v-if="isModeShow">
                            <button class="btn btn-primary px-5 py-2" @click="addConectivity">{{$t('shared.add')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="logDialog" persistent width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.Conectivitylog')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hidelogDialog"></i>
                    </div>
                </v-card-title>
                <v-card-text>
                    <div class="row">
                        <div class="col-md-12 mb-3">
                            <label style="font-weight:bold">{{$t('shared.addRequest')}}</label>
                            <h4 class="user_info_txt" style="background-color: #dee2e6;
                                padding: 10px;border: black;border-radius: 10px;color: black;">
                                {{logModel.addRequest}}
                            </h4>
                        </div>
                        <div class="col-md-12 mb-3">
                            <label style="font-weight:bold">{{$t('shared.addResponse')}}</label>
                            <h4 class="user_info_txt" style=" background-color: #dee2e6; padding:10px;
                                    border: black;border-radius: 10px;color: black;">
                                {{logModel.addResponse}}
                            </h4>
                        </div>
                        <div class="col-md-12 mb-3">
                            <label style="font-weight:bold">{{$t('shared.deleteRequest')}}</label>
                            <h4 class="user_info_txt" style=" background-color: #dee2e6; padding:10px;
                                 border: black;border-radius: 10px;color: black;">
                                {{logModel.deleteRequest}}
                            </h4>
                        </div>
                        <div class="col-md-12 mb-3">
                            <label style="font-weight:bold">{{$t('shared.deleteResponse')}}</label>
                            <h4 class="user_info_txt" style=" background-color: #dee2e6; padding:10px;
                                 border: black;border-radius: 10px;color: black;">
                                {{logModel.deleteResponse}}
                            </h4>
                        </div>
                    </div>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_login" @click="hidelogDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</div>

