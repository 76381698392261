<div class="w-100 h-100">
    <div class="row" style="padding-top: 25px;padding-bottom: 25px;">
        <div class="col-md-12">
            <div class="form-row justify-content-between align-items-baseline">
                <div class="col-auto">
                    <label class="header_text mb-3">
                        {{$t('shared.invoicesList')}}
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 pr-0">
            <div class="card card_layout">
                <div class="card-body">
                    <div class="row">

                        <div class="col-md-3">
                            <div class="input-group mb-3">
                                <input class="form-control border-right-0 border-left" type="text"
                                       v-model="filterModel.invoiceNumber" :placeholder="$t('shared.invoiceNumber')" />
                                <div class="input-group-append">
                                    <span class="input-group-text right gray">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group mb-3">
                                <input class="form-control border-right-0 border-left" type="text"
                                       v-model="filterModel.sendersName" :placeholder="$t('shared.sendersName')" />
                                <div class="input-group-append">
                                    <span class="input-group-text right gray">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="input-group mb-3">
                                <input class="form-control border-right-0 border-left" type="text"
                                       v-model="filterModel.clientName" :placeholder="$t('shared.clientName')" />
                                <div class="input-group-append">
                                    <span class="input-group-text right gray">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group mb-3">
                                <input class="form-control border-right-0 border-left" type="text"
                                       v-model="filterModel.providerName" :placeholder="$t('shared.providerName')" />
                                <div class="input-group-append">
                                    <span class="input-group-text right gray">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-3 mb-3"></div>
                        <div class="col-md-3 mb-3">
                            <div class="input-group mb-3">
                                <label>{{$t('shared.paymentDateFrom')}}</label>
                                <v-menu v-model="createdDateFromMenu" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="input-group mb-3">
                                            <input class="form-control border-right-0 border-left"
                                                   v-model="filterModel.paymentDateFrom" readonly v-bind="attrs"
                                                   ref="createdDatePicker" v-on="on" type="text" />
                                            <div class="input-group-prepend">
                                                <span class="input-group-text right gray">
                                                    <i class="far fa-calendar-alt"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </template>
                                    <v-date-picker v-model="filterModel.paymentDateFrom" no-title scrollable
                                                   @input="createdDateFromMenu = false">
                                    </v-date-picker>
                                </v-menu>
                            </div>
                        </div>

                        <div class="col-md-3 mb-3">
                            <div class="input-group mb-3">
                                <label>{{$t('shared.paymentDateTo')}}</label>
                                <v-menu v-model="createdDateToMenu" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="input-group mb-3">
                                            <input class="form-control border-right-0 border-left"
                                                   v-model="filterModel.paymentDateTo" readonly v-bind="attrs"
                                                   ref="createdDatePicker" v-on="on" type="text" />
                                            <div class="input-group-prepend">
                                                <span class="input-group-text right gray">
                                                    <i class="far fa-calendar-alt"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </template>
                                    <v-date-picker v-model="filterModel.paymentDateTo" no-title scrollable
                                                   @input="createdDateToMenu = false">
                                    </v-date-picker>
                                </v-menu>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 mt-2">
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto pull-left">
                            <button class="btn btn-primary btn-action" @click="reset">
                                {{$t('shared.reset')}}
                            </button>
                        </div>
                        <div class="col-auto pull-left">
                            <button class="btn btn-primary btn-action" @click="exportToExcel">
                                {{$t('shared.exporttoexcel')}}
                            </button>
                        </div>
                        <div class="col-auto pull-left">
                            <button class="btn btn-primary btn-action" @click="search">
                                {{$t('shared.apply')}}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row" style="margin-top: 10px;">
                    <div class="col-md-12 pr-0">
                        <div class="card card_layout">
                            <div class="card-body">
                                <template>
                                    <v-data-table :headers="headers" :items="invoicesModel" :total-items="invoicesCount"
                                                  :rows-per-page-text="$t('shared.rowsPerPage')"
                                                  :rows-per-page-items="[10, 25, 50, 100]" @update:pagination="updatePaginate"
                                                  class="elevation-1 table-striped">
                                        <template v-slot:items="props">
                                            <tr>
                                                <td class="text-center">
                                                    {{ props.item.invoiceNumber }}
                                                </td>
                                                <td class="text-center">
                                                    {{ props.item.sendersName }}
                                                </td>
                                                <td class="text-center">
                                                    {{ props.item.clientName }}
                                                </td>
                                                <td class="text-center">
                                                    {{ props.item.providerName }}
                                                </td>
                                                <td class="text-center">{{ props.item.date }}</td>
                                                <td class="text-center">{{ props.item.amount }}</td>
                                                <td class="text-center">
                                                    <i class="fas fa-file-download Icon_Edit"
                                                       v-if=" props.item.invoiceNumber !== null && props.item.invoiceNumber !== ''"
                                                       @click="download(props.item.paymentReference, false)"></i>
                                                    <i v-if=" props.item.invoiceNumber === null || props.item.invoiceNumber === ''">{{$t('shared.invoiceUnderProcessing')}}</i>
                                                </td>
                                            </tr>
                                        </template>
                                    </v-data-table>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <template>
        <div>
            <VueHtml2pdf :show-layout="false" 
                         :float-layout="true" 
                         :enable-download="true" 
                         :preview-modal="false"
                         :paginate-elements-by-height="900" 
                         filename="Invoice" 
                         :pdf-quality="2" 
                         :manual-pagination="false"
                         pdf-format="legal"
                         pdf-orientation="portrait"
                         pdf-content-width="830px"
                         ref="html2Pdf"
                         @hasDownloaded="hasDownloaded">
                <section slot="pdf-content">
                    <div v-if="downloadIssued" v-html="invoiceHtml"></div>
                </section>
            </VueHtml2pdf>
        </div>
    </template>
</div>