<div class="w-100 h-100">
    <div class="row" style="padding-top: 25px;padding-bottom: 25px;">
        <div class="col-md-12">
            <div class="form-row justify-content-between align-items-baseline">
                <div class="col-auto">
                    <label class="header_text mb-3">
                        {{$t('shared.SenderConnectivitySupport')}}
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 pr-0">
            <div class="card card_layout">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>{{$t('dashboardPage.senderName')}}</label>
                                <input class="form-control" v-model="model.senderName" type="text" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>{{$t('shared.shortcode')}}</label>
                                <input class="form-control" v-model="model.shortcode" type="text" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>{{$t('shared.providers')}}</label>
                                <v-autocomplete v-model="model.providerId"
                                                :items="providerOptions"
                                                item-text="name"
                                                item-value="id"
                                                :label="$t('shared.selectProvider')"
                                                solo>
                                </v-autocomplete>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>{{$t('shared.connectivityStatus')}}</label>
                                <v-autocomplete v-model="model.senderConectivityStatus"
                                                :items="senderConectivityStatusOptions"
                                                item-text="name"
                                                item-value="id"
                                                :label="$t('shared.selectStatus')"
                                                solo>
                                </v-autocomplete>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>{{$t('shared.company')}}</label>
                                <v-autocomplete v-model="model.operatorCompany"
                                                :items="companyOptions"
                                                item-text="name"
                                                item-value="id"
                                                :label="$t('shared.selectCompany')"
                                                solo>
                                </v-autocomplete>
                            </div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.createdDateFrom')}}</label>
                                <v-menu v-model="createdDateFromMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                                        offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="input-group mb-3">
                                            <input class="form-control border-right-0 border-left" v-model="model.createdDateFrom"
                                                   readonly v-bind="attrs" ref="createdDatePicker"
                                                   v-on="on" type="text" />
                                            <div class="input-group-prepend">
                                                <span class="input-group-text right gray">
                                                    <i class="far fa-calendar-alt"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </template>
                                    <v-date-picker v-model="model.createdDateFrom"
                                                   no-title
                                                   scrollable
                                                   @input="createdDateFromMenu = false">
                                    </v-date-picker>
                                </v-menu>
                            </div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.createdDateTo')}}</label>
                                <v-menu v-model="createdDateToMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                                        offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="input-group mb-3">
                                            <input class="form-control border-right-0 border-left" v-model="model.createdDateTo"
                                                   readonly v-bind="attrs" ref="createdDatePicker"
                                                   v-on="on" type="text" />
                                            <div class="input-group-prepend">
                                                <span class="input-group-text right gray">
                                                    <i class="far fa-calendar-alt"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </template>
                                    <v-date-picker v-model="model.createdDateTo"
                                                   no-title
                                                   scrollable
                                                   @input="createdDateToMenu = false">
                                    </v-date-picker>
                                </v-menu>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-2" style="padding: 10px;">
                        <div class="form-row justify-content-end align-items-center">
                            <div class="col-auto pull-left">
                                <button class="btn btn-primary btn-action"
                                        @click="getReport" :disabled="!isExportValid">
                                    {{$t('shared.exportToExcel')}}
                                </button>
                                <button class="btn btn-primary btn-action"
                                        @click="search" :disabled="!isValid">
                                    {{$t('shared.apply')}}
                                </button>
                            </div>
                        </div>
                    </div>
                    <template>
                        <v-data-table :headers="headers"
                                      :items="connectivities"
                                      :total-items="connectivitiesCount"
                                      :pagination.sync="pagination"
                                      :rows-per-page-text="$t('shared.rowsPerPage')"
                                      :rows-per-page-items='[10, 25, 50, 100]'
                                      @update:pagination="updatePaginate"
                                      class="elevation-1 table-striped">
                            <template v-slot:items="props">
                                <tr>
                                    <td class="text-center">{{ getOperatorText(props.item.operator) }}</td>
                                    <td class="text-center">{{ props.item.shortCodeName }}</td>
                                    <td class="text-center">{{ props.item.senderName }}</td>
                                    <td class="text-center">{{ props.item.providerName }}</td>
                                    <td class="text-center">
                                        {{getSenderTypeTextTable(props.item.senderType)}}
                                    </td>
                                    <td class="text-center">
                                        {{props.item.createdDate}}
                                    </td>
                                    <td class="text-center">
                                        {{props.item.lastModifiedDate}}
                                    </td>
                                    <td class="text-center" :class="'status_' + props.item.status">{{ getConectivityStatusText(props.item.status) }}</td>
                                    <td class="text-center btn_menu">
                                        <template>
                                            <v-menu offset-y open-on-hover>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn color="btn-primary"
                                                           v-on="on">
                                                        <div>{{$t('shared.actions')}}</div>
                                                        <i class="fas fa-chevron-down pl-4" style="vertical-align: sub;"></i>
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-tile v-if="props.item.status === 1 && isSuperUser" @click="showMarkAsAddedDialog(props.item.id)">
                                                        <v-list-tile-title class="white">{{$t('shared.ConverttoAdded')}}</v-list-tile-title>
                                                    </v-list-tile>
                                                    <v-list-tile v-if="props.item.status === 3 && isSuperUser" @click="showMarkAsDeletedDialog(props.item.id)">
                                                        <v-list-tile-title class="white">{{$t('shared.ConverttoDeleted')}}</v-list-tile-title>
                                                    </v-list-tile>
                                                    <v-list-tile v-if="props.item.status === 2 && isSuperUser" @click="showResendToOperatorDialog(props.item.id,props.item.status)">
                                                        <v-list-tile-title class="white">{{$t('shared.resendToOperator')}}</v-list-tile-title>
                                                    </v-list-tile>
                                                    <v-list-tile @click="showlogDialog(props.item.id)">
                                                        <v-list-tile-title class="white">{{$t('shared.details')}}</v-list-tile-title>
                                                    </v-list-tile>
                                                </v-list>
                                            </v-menu>
                                        </template>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </template>
                </div>
            </div>
        </div>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="markAsAddedDialog" persistent width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.convertToAdded')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideMarkAsAddedDialog"></i>
                    </div>
                </v-card-title>
                <v-card-text class="text-center">
                    <img :src="require('../img/folder.svg')" />
                    <p class="mt-3">
                        {{$t('shared.markAsAddedInputText')}}
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hideMarkAsAddedDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="MarkAsAdded">{{$t('shared.convert')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="markAsDeletedDialog" persistent width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.convertToDeleted')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideMarkAsDeletedDialog"></i>
                    </div>
                </v-card-title>
                <v-card-text class="text-center">
                    <img :src="require('../img/folder.svg')" />
                    <p class="mt-3">
                        {{$t('shared.markAsDeletedInputText')}}
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hideMarkAsDeletedDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="MarkAsDeleted">{{$t('shared.convert')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="resendToOperatorDialog" persistent width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.resendToOperator')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideResendToOperatorDialog"></i>
                    </div>
                </v-card-title>
                <v-card-text class="text-center">
                    <img :src="require('../img/folder.svg')" />
                    <p class="mt-3">
                        {{$t('shared.resendToOperatorInputText')}}
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hideResendToOperatorDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="resendToOperator">{{$t('shared.resend')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="logDialog" persistent width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.Conectivitylog')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hidelogDialog"></i>
                    </div>
                </v-card-title>
                <v-card-text>
                    <div class="row">
                        <div class="col-md-12 mb-3">
                            <label style="font-weight:bold">{{$t('shared.addRequest')}}</label>
                            <h4 class="user_info_txt" style="background-color: #dee2e6;
                                padding: 10px;border: black;border-radius: 10px;color: black;">
                                {{logModel.addRequest}}
                            </h4>
                        </div>
                        <div class="col-md-12 mb-3">
                            <label style="font-weight:bold">{{$t('shared.addResponse')}}</label>
                            <h4 class="user_info_txt" style=" background-color: #dee2e6; padding:10px;
                                    border: black;border-radius: 10px;color: black;">
                                {{logModel.addResponse}}
                            </h4>
                        </div>
                        <div class="col-md-12 mb-3">
                            <label style="font-weight:bold">{{$t('shared.deleteRequest')}}</label>
                            <h4 class="user_info_txt" style=" background-color: #dee2e6; padding:10px;
                                 border: black;border-radius: 10px;color: black;">
                                {{logModel.deleteRequest}}
                            </h4>
                        </div>
                        <div class="col-md-12 mb-3">
                            <label style="font-weight:bold">{{$t('shared.deleteResponse')}}</label>
                            <h4 class="user_info_txt" style=" background-color: #dee2e6; padding:10px;
                                 border: black;border-radius: 10px;color: black;">
                                {{logModel.deleteResponse}}
                            </h4>
                        </div>
                    </div>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_login" @click="hidelogDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</div>

